import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {GenGenLocation} from 'src/app/generated/serverModels/GenGenLocation';
import {GenUser} from 'src/app/generated/serverModels/GenUser';
import {GenWorkflowStatus} from 'src/app/generated/serverModels/GenWorkflowStatus';
import {BreadCrumbService} from 'src/app/services/bread-crumb.service';
import {DocumentTagService} from 'src/app/services/document-tag.service';
import {LocationService} from 'src/app/services/location.service';
import {TaskService} from 'src/app/services/task.service';
import {UserService} from 'src/app/services/user.service';
import {Project} from 'src/app/types/project.class';
import {Task} from 'src/app/types/task.class';
import {User} from 'src/app/types/user.class';

import {GenTrelloCard} from '../../../../generated/serverModels/GenTrelloCard';
import {TrelloService} from '../../../../services/trello.service';
import {MatSelectChange} from '@angular/material/select';
import {PsDataset} from '../../../../types/ps-dataset.class';
import {FileTransferDialogComponent} from '../file-transfer-dialog/file-transfer-dialog.component';
import {GenTrelloData} from '../../../../generated/serverModels/GenTrelloData';
import {IPsLayer} from '../../../../types/ps-layer.interface';
import {PsDatasetStyle} from '../../../../types/ps-dataset-style.class';
import {PromptComponent, PromptData} from '../../../shared/components/prompt/prompt.component';
import {GenScope} from '../../../../generated/serverModels/GenScope';

@Component({
  selector: 'portal-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit, AfterViewInit {


  get trelloIsAuth(): boolean {
    return this.trelloService.authenticated.value;
  }

  constructor(private popup: MatSnackBar,
              public router: Router,
              public locationService: LocationService,
              public tagService: DocumentTagService,
              public activatedRoute: ActivatedRoute,
              public breadcrumbService: BreadCrumbService,
              public taskService: TaskService,
              public userService: UserService,
              public dialog: MatDialog,
              private trelloService: TrelloService) {

    this.trelloEnabled = this.trelloService.isEnabled;

    if (this.trelloEnabled) {
      if (!this.trelloService.authenticated.value) {
        this.trelloLogin();
      }
      this.trelloService.getTrelloData().subscribe((data) => {
        this.trelloCards = data.cards;
        this.trelloData = data;
        data.lists.forEach((list) => {
          this.listNameMap.set(list.trelloId, list.name);
        });
      });
    }

    this.locationControl = new UntypedFormControl();
    this.tagControl = new UntypedFormControl();
    this.contributorControl = new UntypedFormControl();

    const task = this.task = new Task();
    task.publishedTimestamp = moment().startOf('day');
    task.releaseTimestamp = moment().startOf('day');
    locationService.getAllCountries().subscribe((locations) => {
      this.locations = locations.sort((l1, l2) => {
        return (l1.name < l2.name) ? -1 : (l1.name > l2.name) ? 1 : 0;
      });
      if (this.locationControl.value) {
        this.filteredLocations = this.locationControl.valueChanges.pipe(
          map(name => {
            this.searchText = name;
            return this.filterLocations(name);
          }));
      } else {
        this.filteredLocations = this.locationControl.valueChanges.pipe(
          startWith(undefined),
          map(name => {
            this.searchText = name;
            return this.filterLocations(name);
          }));
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initTask();
      }
    });

    this.tagService.getAll().subscribe((tags) => {
      this.tags = tags.sort((tag1, tag2) => {
        return (tag1.toLowerCase() < tag2.toLowerCase()) ? -1 : (tag1.toLowerCase() > tag2.toLowerCase()) ? 1 : 0;
      });

      this.filteredTags = this.tagControl.valueChanges.pipe(
        startWith(undefined),
        map(name => {
          this.tagSearchText = name;
          return this.filterTags(name);
        }));
    });


    this.userService.getContributors().subscribe((users) => {
      this.users = users.sort((user1, user2) => {
        return (user1.name.toLowerCase() < user2.name.toLowerCase()) ? -1 : (user1.name.toLowerCase() > user2.name.toLowerCase()) ? 1 : 0;
      }).filter((user) => {
        return user.isContributor();
      });

      if (this.contributorControl.value) {
        this.filteredContributors = this.contributorControl.valueChanges.pipe(
          map(name => {
            return this.filterUsers(name);
          }));
      } else {
        this.filteredContributors = this.contributorControl.valueChanges.pipe(
          startWith(undefined),
          map(name => {
            return this.filterUsers(name);
          }));
      }
    });

  }

  lastIframeDownload: string;
  attemptedDownload = false;
  wmsLayer: IPsLayer;
  wmsStyles: PsDatasetStyle[] = [];
  project: Project;
  tagSearchText: any;
  tagControl: UntypedFormControl;
  locations: GenGenLocation[];
  filteredLocations: Observable<GenGenLocation[]>;
  tags: string[];
  filteredTags: Observable<string[]>;
  locationControl: UntypedFormControl;
  searchText: string;
  persistedStatus: GenWorkflowStatus;
  cardToLink: GenTrelloCard;
  existingTrelloCard: GenTrelloCard;

  scopes: GenScope[] = GenScope.values();

  contributorControl: UntypedFormControl;
  filteredContributors: Observable<User[]>;
  @ViewChild('downloadFrame', {static: true})
  downloadFrame: ElementRef;
  users: User[];
  user: User;

  task: Task;

  trelloEnabled = false;

  @Input() modalView = false;
  @Input() modalViewData: ModalViewData;
  @Input() saveSub: BehaviorSubject<any>;
  @Output() savedTask = new EventEmitter();
  @Output() validityChange = new EventEmitter<boolean>();


  @ViewChild('tagAutoComplete')
  tagAutoComplete: MatAutocomplete;

  file: any;
  thumbnailFile: File;
  createWms: boolean;
  countServices: boolean;
  lastProgressUpdateTime: number;
  statuses: GenWorkflowStatus[] = GenWorkflowStatus.values();
  trelloCards: GenTrelloCard[] = [];
  trelloData: GenTrelloData;
  listNameMap: Map<string, string> = new Map<string, string>();

  //noinspection JSMethodCanBeStatic
  uploadInProgress: boolean;
  uploadPercentage: number;

  checkValidity() {
    this.validityChange.emit(!!this.task.name && !!this.task.releaseNotes && !!this.task.releaseTimestamp);
  }


  ngAfterViewInit(): void {
    this.downloadFrame.nativeElement.onload = () => {
      if (!this.attemptedDownload) {
        // Soo... Firefox fires this when the iframe is added before anything has been clicked.
        return;
      }
      let retryFailed = false;
      try {
        if (this.lastIframeDownload) {
          window.open(this.lastIframeDownload);
        } else {
          retryFailed = true;
        }
      } catch (e) {
        retryFailed = true;
      }
      if (retryFailed) {
        window.alert('Download failed...');
      }
    };
  }


  ngOnInit() {
    if (this.saveSub) {
      this.saveSub.subscribe((val) => {
        if (val) {
          this.save();
        }
      });
    }
    this.initTask();
    if (!this.modalView) {
      this.breadcrumbService.setProjectCrumb(this.router.url.split('/').slice(1, 4), this.project.name, this.project.id);
    }
  }

  public initTask() {
    let task: Task;
    if (this.modalView) {
      this.user = this.modalViewData.user;
      this.project = this.modalViewData.project;
      task = this.modalViewData.task;
    } else {
      this.user = this.activatedRoute.snapshot.data.user;
      this.project = this.activatedRoute.snapshot.data.project;
      task = this.activatedRoute.snapshot.data.task;
    }

    if (task) {
      this.task = task;
      this.locationControl.setValue(this.task.location);
      this.contributorControl.setValue(this.concatName(this.task.contributor));
      if (this.task.trelloId && this.trelloEnabled) {
        this.trelloService.getCardById(this.task.trelloId).subscribe((card) => {
          this.existingTrelloCard = card;
        });
      }
      if (this.task.hasWms()) {
        const layerName = this.task.url[0].split('/').pop();
        this.taskService.getLayer(layerName).subscribe(res => {
          this.wmsLayer = res;
          if (this.wmsLayer) {
            this.taskService.getWmsStyles().subscribe((styles) => {
              this.wmsStyles = styles;
            });
          }
        });
      }
    } else {
      this.task.projectId = this.project.id;
      this.task.status = GenWorkflowStatus.InQueue;
    }
    this.persistedStatus = this.task.status;
  }

  public filterUsers(val: string) {
    if (!val || !val.toLowerCase) {
      return this.users;
    }
    val = val.toLowerCase().trim();
    const searchArr = val.split(' ');
    return this.users.filter(user => {
      const haystack = [
        user.firstName,
        user.lastName,
        user.email,
        user.name
      ]
        .concat(user.roles.map(role => role.name))
        .map(str => str.toLowerCase())
        .join();
      return ((!this.project.members || this.project.members.indexOf(user) === -1) && searchArr.every((val) => haystack.indexOf(val) !== -1));
    });
  }

  filterLocations(val: string) {
    if (!val || !val.toLowerCase) {
      return this.locations;
    }
    val = val.toLowerCase();
    return this.locations.filter(l => l.name.toLowerCase().indexOf(val) >= 0);
  }

  selectLocation(location: GenGenLocation): void {
    this.task.locationId = location ? location.id : undefined;
  }

  selectTag(tag: string): void {
    if (!this.task.tags) {
      this.task.tags = [];
    }
    this.task.tags.push(tag);
    const uniqueTags = {};
    this.task.tags.forEach(t => uniqueTags[t] = true);
    this.task.tags = Object.keys(uniqueTags);

    this.tagControl.setValue('');

  }

  fileAdded(file: File) {
    this.file = file;
    this.task.status = GenWorkflowStatus.Review;
  }

  save(): void {
    if (this.existingTrelloCard && this.task.status !== this.persistedStatus) {
      this.task.syncTrello = this.trelloIsAuth;
    }
    if (this.persistedStatus && this.persistedStatus.name === 'Complete' && !this.user.isPublisher() && !this.user.isAdmin()) {
      const promptData: PromptData = {
        level: 'med',
        type: 'task',
        action: 'modify',
        name: `${this.task.name}? It will need review if modified.`,
        hasEnding: true
      };
      this.dialog.open(PromptComponent, {data: promptData, panelClass: 'dialog-with-no-padding', width: '50vw'})
        .afterClosed()
        .subscribe(confirmed => {
          if (confirmed) {
            this.task.status = GenWorkflowStatus.Review;
            this.actuallySave();
          }
        });
    } else if (this.cardToLink) {
      this.task.syncTrello = true;
      this.task.trelloId = this.cardToLink.trelloId;
      this.actuallySave();
    } else {
      this.actuallySave();
    }
  }

  actuallySave() {
    if (!this.task.name) {
      this.popup.open('Name is required', 'okay', {duration: 5000, panelClass: ['failure']});
      return;
    }
    if (!this.task.releaseNotes) {
      this.popup.open('Description is required', 'okay', {duration: 5000, panelClass: ['failure']});
      return;
    }

    const me = this;
    const creating = !this.task.id;
    const file = this.file;

    const redirectRoute = `project/${this.project.id}/task`;

    this.uploadInProgress = true;
    this.uploadPercentage = 0;
    this.lastProgressUpdateTime = 0;

    this.taskService.save(this.task, file, this.thumbnailFile)
      .subscribe(response => {
        if (response instanceof ProgressEvent) {
          if (response.lengthComputable && (new Date().getTime() - this.lastProgressUpdateTime) > 500) {
            this.uploadPercentage = response.loaded / response.total * 100.0;
            this.lastProgressUpdateTime = new Date().getTime();
          }
        } else if (response instanceof XMLHttpRequest) {
          if (this.modalView) {
            const createdTask = new Task(JSON.parse(response.response));
            this.savedTask.emit(createdTask);
          }
          this.uploadInProgress = false;
          const msg: string = me.task.id ? 'Task Updated' : 'Task Uploaded';
          me.popup.open(msg, 'okay', {duration: 2000, panelClass: ['success']});

          if (this.createWms) {
            const createdTask = new Task(JSON.parse(response.response));
            this.taskService.createWms(createdTask, this.countServices).subscribe(() => {
              console.info('creating WMS');
            });
          }
          if (this.wmsLayer) {
            this.taskService.updateLayer(this.wmsLayer).subscribe(() => {
              console.info('updating WMS layer');
            });
          }
          if (creating) {
            if (!this.modalView) {
              me.router.navigate([redirectRoute]).then(() => {
                if (this.createWms) {
                  me.popup.open('You will notified by email when the WMS is created.', 'okay', {
                    duration: 60000,
                    panelClass: ['success']
                  });
                }
              });
            }
          }
          this.taskService.taskChange.next(this.taskService.taskChange.value + 1);

          if (this.trelloEnabled) {
            if (this.cardToLink && this.task.trelloId && this.trelloService.getStatusFromIdList(this.cardToLink.idList) != this.task.status && this.trelloIsAuth) {
              this.trelloService.updateCardListId(this.task.trelloId, this.trelloService.getIdListFromStatus(this.task.status)).then(() => {
                console.log('success');
              }).catch(() => {
                console.log('failure');
              });
            } else if (this.persistedStatus !== this.task.status && this.task.trelloId && this.trelloIsAuth) {
              const idList = this.trelloService.getIdListFromStatus(this.task.status);
              this.trelloService.updateCardListId(this.task.trelloId, idList).then(() => {
                console.log('success');
              }).catch(() => {
                console.log('failure');
              });
            }
          }

          this.persistedStatus = this.task.status;
        }
      }, (response) => {
        this.uploadInProgress = false;
        let msg = 'Save Failed';
        try {
          const error = JSON.parse(response.responseText);
          if (error && error.userMessage) {
            msg += (': ' + error.userMessage);
          }
        } catch (err) {
        }
        me.popup.open(msg, 'okay', {duration: 300000, panelClass: ['failure']});
      });

  }


  promptWMS() {
    if (this.task.originalFilename && this.isLandScanTask()) {
      const config: MatDialogConfig = {
        data: {
          filename: this.task.originalFilename,
        }
      };

      this.dialog.open(FileTransferDialogComponent, config).afterClosed()
        .pipe(filter(val => !!val))
        .subscribe((dataset: PsDataset) => {
          if (dataset) {
            this.popup.open('WMS Layer will be created when task is saved.', 'okay', {
              duration: 5000,
              panelClass: ['success']
            });
            this.countServices = dataset.createCountData;
            this.createWms = true;
          }
        });
    }

  }

  nameFromLocationFn() {
    return (location: GenGenLocation) => location ? location.name : '';
  }

  public filterTags(val: string) {
    val = (val || '').toLowerCase().trim();
    return this.tags.filter(tag => (!this.task.tags || this.task.tags.indexOf(tag) === -1) && tag.toLowerCase().indexOf(val) >= 0);

  }

  public addInputTextAsTag() {
    this.selectTag(this.tagSearchText);
    this.tagControl.setValue('');
  }

  prettyStatus(status: GenWorkflowStatus): string {
    return status.name.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  removeTag(tag: string) {
    this.task.tags = this.task.tags.filter(t => t !== tag);
    this.tagControl.setValue('');
  }

  selectContributor(user: User) {
    this.task.contributor = user;
  }

  displayUserInfo(user: User) {
    return (!user) ? '' : `${user.name}, ${user.firstName} ${user.lastName}, ${user.email}`;
  }

  concatName(user: GenUser) {
    return (user) ? `${user.firstName} ${user.lastName}` : ``;
  }

  fetch(task: Task): void {
    this.attemptedDownload = true;
    const iframe = this.downloadFrame.nativeElement;

    const downloadPath: string = task.getDownloadPath();
    if (iframe && !task.originalFilename.toLowerCase().endsWith('.pdf')) {
      this.lastIframeDownload = downloadPath;
      try {
        iframe.src = downloadPath;
      } catch (e) {
        window.open(this.lastIframeDownload);
        this.lastIframeDownload = undefined;
      }
    } else {
      this.lastIframeDownload = undefined;
      window.open(downloadPath);
    }

    this.trackDownload(task);
  }


  trackDownload(task: Task) {
    try {
      if (task.myDownloadCount <= 0) {
        task.myDownloadCount = 1;
      }
      return true;
    } catch (err) {
      console.error(err);
      return true;
    }
  }

  deleteFile() {
    const promptData: PromptData = {
      level: 'high',
      type: 'file',
      action: 'delete',
      name: `${(this.task.originalFilename) ? this.task.originalFilename : this.file.name}? The file will be removed immediately.`,
      hasEnding: true
    };
    this.dialog.open(PromptComponent, {
      data: promptData,
      panelClass: 'dialog-with-no-padding',
      width: '50vw'
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.task.status = GenWorkflowStatus.InProgress;
        this.taskService.deleteFile(this.task).subscribe((task) => {
          this.task.originalFilename = task.originalFilename;
          this.task.serverFilename = task.serverFilename;
          this.task.thumbnail = task.thumbnail;
          this.file = undefined;
        });
      }
    });
  }

  isLandScanTask() {
    if (this.task.name && this.task.name.indexOf('LandScan') >= 0) {
      return true;
    }
    return this.task.tags && this.task.tags.some((tag) => {
      return tag.indexOf('LandScan') >= 0;
    });
  }

  trelloLogin() {
    this.trelloService.authenticate().then(() => {
      this.trelloService.authenticated.next(true);
    }).catch(() => {
      this.trelloService.authenticated.next(false);
    });
  }

  statusChange(event: MatSelectChange) {
    if ((event.value as GenWorkflowStatus) === GenWorkflowStatus.Complete) {
      this.promptWMS();
    }
  }

  updateDocumentationTag() {
    if (!this.task.tags) {
      this.task.tags = [];
    }
    const index = this.task.tags.findIndex(t => t === 'Documentation');
    if (this.task.sticky) {
      if (index === -1) {
        this.task.tags.unshift('Documentation');
      }
    } else {
      if (index !== -1) {
        this.task.tags.splice(index, 1);
      }
    }
  }

  isValidStatus(status: GenWorkflowStatus) {
    if (GenWorkflowStatus.Complete === status && !this.file && !this.task.originalFilename) {
      return false;
    }
    return true;
  }
}

export interface ModalViewData {
  user?: User;
  project?: Project;
  task?: Task;
}
