import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthenticationFormComponent} from './components/authentication-form/authentication-form.component';
import {ReportingComponent} from './components/reporting/reporting.component';
import {AuthenticatedUserGuard} from './guards/authenticated-user.guard';
import {RolesGuard} from './guards/roles.guard';
import {UserSurveyComponent} from './components/user-survey/user-survey.component';
import {SurveyGuard} from './guards/survey.guard';
import {PocLandingComponent} from './components/poc-verification/poc-landing.component';
import {PocSuccessComponent} from './components/poc-verification/poc-success.component';
import {PocFailureComponent} from './components/poc-verification/poc-failure.component';
import {ManagedSettingResolver} from './resolvers/managed-setting.resolver';


const routes: Routes = [
  {
    path: 'login',
    component: AuthenticationFormComponent,
    pathMatch: 'full'
  },
  {
    path: 'poc/approve/:code',
    component: PocLandingComponent,
    pathMatch: 'full'
  },
  {
    path: 'poc/deny/:code',
    component: PocLandingComponent,
    pathMatch: 'full'
  },
  {
    path: 'poc/success',
    component: PocSuccessComponent,
    pathMatch: 'full'
  },
  {
    path: 'poc/failure',
    component: PocFailureComponent,
    pathMatch: 'full'
  },
  {
    path: 'survey',
    component: UserSurveyComponent,
    pathMatch: 'full',
    resolve: {
      user: AuthenticatedUserGuard
    },
    canActivate: [SurveyGuard]
  }, {
    path: 'reporting',
    component: ReportingComponent,
    resolve: {
      user: AuthenticatedUserGuard
    },
    data: {
      anyRole: ['ROLE_ADMIN', 'ROLE_PUBLISHER', 'ROLE_SPONSOR', 'APPLICATION_MANAGER']
    },
    canActivate: [RolesGuard]
  }, {
    path: 'project',
    loadChildren: () => import( './modules/project/project.module' ).then(m => m.ProjectModule),
  }, {
    path: 'user',
    loadChildren: () => import( './modules/user/user.module' ).then(m => m.UserModule)
  }, {
    path: 'map',
    loadChildren: () => import( './modules/map/map.module' ).then(m => m.MapModule)
  }, {
    path: 'admin',
    loadChildren: () => import( './modules/admin/admin.module' ).then(m => m.AdminModule)
  }
];

const routeDefaults: Routes = [{
  path: '',
  redirectTo: 'project',
  pathMatch: 'full'
},
  {
    path: '**',
    redirectTo: 'project',
    pathMatch: 'full'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes.concat(routeDefaults))],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
