import {NgModule} from '@angular/core';

import {MapPreviewDialogComponent} from './components/map-preview-dialog/map-preview-dialog.component';
import {PromptComponent} from './components/prompt/prompt.component';
import {CommonModule} from '@angular/common';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {DropFileDirective} from './directives/drop-file.directive';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {TaskTableComponent} from './components/task-table/task-table.component';
import {ShareFileComponent} from './components/share-file/share-file.component';
import {ReleaseNotesDialogComponent} from './components/release-notes-dialog/release-notes-dialog.component';
import {MaterialModule} from '../material/material.module';
import {JoyrideModule} from 'ngx-joyride';

const components = [
  MapPreviewDialogComponent,
  PromptComponent,
  AutoFocusDirective,
  DropFileDirective,
  FileUploadComponent,
  TaskTableComponent,
  ShareFileComponent,
  ReleaseNotesDialogComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    MaterialModule,
    CommonModule,
    JoyrideModule.forChild(),
  ],
  exports: [...components],
})
export class SharedModule {
}
