import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'portal-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

  constructor(public dialogRef: MatDialogRef<PromptComponent>, @Inject(MAT_DIALOG_DATA) public data: PromptData) {
  }

}

export interface PromptData {
  level: 'med' | 'high';
  action: string;
  type: string;
  name: string;
  hasEnding?: boolean;
}
