import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationFormComponent} from './components/authentication-form/authentication-form.component';
import {ReportingComponent} from './components/reporting/reporting.component';
import {UserSurveyComponent} from './components/user-survey/user-survey.component';
import {SharedModule} from './modules/shared/shared.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {PocLandingComponent} from './components/poc-verification/poc-landing.component';
import {PocSuccessComponent} from './components/poc-verification/poc-success.component';
import {PocFailureComponent} from './components/poc-verification/poc-failure.component';
import {MaterialModule} from './modules/material/material.module';
import {JoyrideModule} from 'ngx-joyride';
import {ConfirmPromptComponent} from './components/confirm-prompt/confirm-prompt.component';
import {SanitizePipe} from './pipes/sanitize.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationFormComponent,
    UserSurveyComponent,
    ReportingComponent,
    PocLandingComponent,
    PocSuccessComponent,
    PocFailureComponent,
    ConfirmPromptComponent,
    SanitizePipe,
  ],
  imports: [
    HttpClientModule,
    MaterialModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    JoyrideModule.forRoot(),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
