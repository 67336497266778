import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  template: `
    <h1 mat-dialog-title>{{ promptText }}</h1>
    <div mat-dialog-content>
      <p *ngIf="promptBodyText" [innerHTML]="promptBodyText | sanitize">
      <p>
        <mat-form-field *ngIf="showTextPrompt" style="width:100%;">
          <mat-label>{{ fieldPlaceholder }}</mat-label>
          <!--suppress HtmlUnknownAttribute -->
          <textarea required
                    *ngIf="inputType === 'textarea'"
                    matInput
                    [(ngModel)]="message"
                    [style.height]="inputHeight"
                    matTextareaAutosize
                    matAutosizeMaxRows="5"
                    (keydown.enter)="onEnter($event)"
                    [ngModelOptions]="{standalone: true}"></textarea>
          <input required
                 type="text"
                 *ngIf="inputType === 'text'"
                 matInput
                 [style.height]="inputHeight"
                 [(ngModel)]="message"
                 (keydown.enter)="onEnter($event)"
                 [ngModelOptions]="{standalone: true}">
          <div matSuffix *ngIf="inputSuffix">{{ inputSuffix }}</div>
          <mat-hint style="color: #3946a9; font-weight: bold;" *ngIf="hintText">{{ hintText }}</mat-hint>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="full-width">
      <div class="full-width flex-align-end-center gap-10">
        <button *ngIf="cancelButtonText" mat-raised-button (click)="cancel()">
          {{ cancelButtonText }}
        </button>
        <button mat-raised-button
                (click)="confirm()"
                color="primary"
                [disabled]="!isFormValid()">
          {{ confirmButtonText }}
        </button>
      </div>
    </div>`
})
export class ConfirmPromptComponent {
  readonly requireText: boolean = false;
  message = '';
  readonly confirmButtonText: string = 'Yes';
  readonly cancelButtonText: string = 'No';
  promptText: string;
  showTextPrompt: boolean;
  promptBodyText: string;
  fieldPlaceholder = 'Message';
  hintText?: string;
  submitOnEnter = false;
  invalidPattern?: string;
  messagePrompt = 'Message';
  inputType: 'text' | 'textarea' = 'textarea';
  inputSuffix: string;
  inputHeight: string = undefined;

  constructor(public dialogRef: MatDialogRef<ConfirmPromptComponent>,
              @Inject(MAT_DIALOG_DATA) data: ConfirmPromptConfig) {
    if (!data) {
      // noinspection TypeScriptSuspiciousConstructorParameterAssignment
      data = {};
    }
    this.promptText = data.promptText || 'Are you sure?';
    this.promptBodyText = data.promptBodyText || undefined;
    this.showTextPrompt = !!data.showTextPrompt;
    this.hintText = data.hintText;
    this.submitOnEnter = !!data.submitOnEnter;

    this.requireText = data.requireText !== undefined ? data.requireText : !!data.showTextPrompt;

    if (data.fieldPlaceholder) {
      this.fieldPlaceholder = data.fieldPlaceholder;
    }

    this.confirmButtonText = data.confirmButtonText;
    this.cancelButtonText = data.cancelButtonText;
    if (!this.confirmButtonText) {
      this.confirmButtonText = data.showTextPrompt ? 'OK' : 'Yes';
    }
    if (!this.cancelButtonText && this.cancelButtonText !== '') {
      this.cancelButtonText = data.showTextPrompt ? 'Cancel' : 'No';
    }
    if (data.messagePrompt) {
      this.messagePrompt = data.messagePrompt;
    }
    if (data.initialMessage) {
      this.message = this.sanitize(data.initialMessage);
    }
    if (data.inputType) {
      this.inputType = data.inputType;
    }
    if (data.inputSuffix) {
      this.inputSuffix = data.inputSuffix;
    }
    if (data.inputHeight) {
      this.inputHeight = data.inputHeight;
    }
  }

  isFormValid(): boolean {
    return !this.requireText || !!this.message;

  }

  cancel() {
    this.dialogRef.close({proceed: false});
  }

  confirm() {
    this.dialogRef.close({proceed: true, message: this.message});
  }

  private sanitize(msg: string): string {
    if (this.invalidPattern) {
      return msg.replace(new RegExp(this.invalidPattern, 'g'), '_');
    }
    return msg;
  }

  onEnter(event: any) {
    if (this.submitOnEnter) {
      event.preventDefault();
      event.stopPropagation();
      if (this.isFormValid()) {
        this.confirm();
      }
    }
  }

}

export interface ConfirmPromptConfig {
  inputHeight?: string;
  submitOnEnter?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  promptText?: string;
  messagePrompt?: string;
  initialMessage?: string;
  promptBodyText?: string;
  showTextPrompt?: boolean;
  requireText?: boolean;
  fieldPlaceholder?: string;
  hintText?: string;
  inputSuffix?: string;
  inputType?: 'text' | 'textarea';
}
