<div mat-dialog-title>
  <div class="flex-row flex-align-start-center gap-8">
    <mat-icon class="prompt-icon" [class.med]="data.level === 'med'" [class.high]="data.level === 'high'">
      error
    </mat-icon>
    {{ data.action.charAt(0).toUpperCase() + data.action.slice(1) }} {{ data.type.charAt(0).toUpperCase() + data.type.slice(1) }}
  </div>
</div>
<mat-dialog-content>
  <div class="full-width full-height content">
    Are you sure you want to {{ data.action.toLowerCase() }}
    the {{ data.type.toLowerCase() }} {{ data.name }}{{ (!data.hasEnding) ? '?' : '' }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-row flex-align-end-center gap-12">
    <button mat-raised-button (click)="dialogRef.close(false)">
      Cancel
    </button>
    <button mat-raised-button color="primary"
            (click)="dialogRef.close(true)">
      {{ data.action.charAt(0).toUpperCase() + data.action.slice(1) }}
    </button>
  </div>
</mat-dialog-actions>
